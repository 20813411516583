<template>
    <div class="container border-container">
        <div class="search-container">
            <span class="list-title">学生列表</span>
        </div>
        <div class="table-container">
            <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" size="medium"
                :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                :cell-style="{height: '50px',padding: '5px 0'}" header-align="center">
                <el-table-column label="名称" min-width="120" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        {{scope.row.name || '-'}}
                    </template>
                </el-table-column>
                <el-table-column label="生日" min-width="125" align="center">
                    <template slot-scope="scope">
                        {{scope.row.birthday | timeFilter(2)}}
                    </template>
                </el-table-column>
                <el-table-column label="性别" min-width="125" align="center">
                    <template slot-scope="scope">
                        {{scope.row.gender | genderFilter}}
                    </template>
                </el-table-column>
                <!-- <el-table-column label="订阅状态" min-width="80">
                    <template slot-scope="scope">
                        {{scope.row.status | parkStatusFilter}}
                    </template>
                </el-table-column> -->
                <el-table-column prop="create_time" label="注册时间" min-width="155" align="center">
                    <template slot-scope="scope">
                        {{scope.row.create_time | timeFilter}}
                    </template>
                </el-table-column>
                <div class="" slot="empty">
                    <no-data></no-data>
                </div>
            </el-table>
        </div>
        <div class="page-container">
            <el-pagination layout="total, sizes, prev, pager, next" background
                @size-change="pageSizeChange"
                @current-change="pageChange"
                :current-page="currentPage"
                :page-sizes="[total, 10, 15, 20, 25]"
                :total="total" :page-size="pageSize">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import Core from 'core';
export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {
        orgId: {
            type: [Number,String]
        },
    },
    data() {
        return {
            total: 0,
            currentPage: 1,
            pageSize: 10,

            tableData: [],
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {
        this.getTableData();
    },
    methods: {
        pageChange(page) { // 页面改变
            this.currentPage = page;
            this.getTableData();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getTableData();
        },
        getTableData() {
            Core.Api.CourseOrder.listByOrgId(
                this.currentPage,
                this.pageSize,
                this.orgId
            ).then(res => {
                console.log('getTableData res:', res)
                this.total = res.count
                this.tableData = res.list
            });
        },
    }
};
</script>
<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .search-container {
        @include flex(row, space-between, center);
    }
}
</style>